.input {
  margin-bottom: 35px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.inputLabel {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.inputLabelText {
  padding-right: 30px;
  text-align: left;
  flex: 1;
}

.inputField {
  flex-basis: 370px;
  padding: 12px 18px;
  border-radius: 6px;
  font-size: 16px;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 730px) {
  .inputLabel {
    display: block;
    text-align: left;
  }

  .inputLabelText {
    margin-bottom: 10px;
  }

  .inputField {
    width: 100%;
  }
}