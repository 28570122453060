.textarea {
  margin-bottom: 35px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.textareaLabel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.textareaLabelText {
  padding-right: 30px;
  text-align: left;
  flex: 1;
}

.textareaField {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    resize: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    padding: 12px 18px;
    flex-basis: 370px;
    width: 100%;
}
.textareaField:focus {
    border: 1px solid #4A61DD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 730px) {
  .textareaLabel {
    display: block;
    text-align: left;
  }

  .textareaLabelText {
    margin-bottom: 10px;
  }

  .textareaField {
    width: 100%;
  }
}
