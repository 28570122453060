.nav {
  height: 90px;
  width: 100%;
}

.nav__container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.nav__items {
  display: flex;
  height: 100%;
  flex-direction: row;
  list-style: none;
  padding: 0;
  align-items: center;
}

.nav__item {
  display: flex;
  align-items: center;
  margin-right: 28px;
  height: 100%;
  color: #000000;
  cursor: pointer;
  position: relative;
}
.nav__item:last-child {
    margin-right: 0;
}

.nav__item_active {
    color: #4A61DD;
}
.nav__item_active:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: 20px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #4A61DD;
}

.nav__link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: inherit;
    padding-bottom: 14px;
    cursor: inherit;
    font-family: 'Montserrat', sans-serif;
}

.nav__link_active {
  border-bottom: 1px solid #4A61DD;
  color: #4A61DD;
}

.orders-count {
  display: inline-block;
  padding: 0px 12px;
  background: #4A61DD;
  color: #FFF;
  font-weight: 500;
  height: 24px;
  display: flex;
  align-items: center;
  transform: translateY(-7px);
  margin-left: 10px;
  border-radius: 36px;
}


@media screen and (max-width: 990px) {
  .nav__link {
    font-size: 14px;
  }

  .nav__item {
    margin-right: 14px;
  }
}


@media screen and (max-width: 900px) {
  .nav__link {
    font-size: 10px;
  }

  .nav__item {
    margin-right: 8px;
  }

  .orders-count {
    height: 16px;
    font-size: 10px;
    padding: 0px 6px;
  }
}

@media screen and (max-width: 770px) {
  .nav {
    height: 50px;
  }

  .nav__link {
    padding-bottom: 6px;
  }
}

@media screen and (max-width: 770px) {
  .nav {
    height: auto;
    position: relative;
  }

  .nav__link {
    font-size: 16px;
  }

  .nav__items {
    display: block;
    position: absolute;
    left: -200px;
    height: auto;
    top: 0;
  }

  .orders-count {
    transform: translateY(-3px);
  }

  .nav__item {
    margin-bottom: 20px;
  }
}
