@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  position: relative;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

#root {
  overflow: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}
html,
body {
  font-family: Montserrat, Arial, sans-serif;
  min-height: 100%;
  padding: 0;
  margin: 0;
}
.style_header__21AOY {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 30px;
}

.style_headerContent__fyAv7 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.style_container__mLpjI {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}

.style_footer__3n-FN {
  background: #000000;
  height: 90px;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}

.style_footer__brand__3KdCB {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.style_footer__container__gEZMY {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}

.style_footer__item__7EWvk {
    margin-right: 40px;
}

.style_footer__items__1wuGN {
    display: -webkit-flex;
    display: flex;
    list-style: none;
    padding: 0;
}

.style_footer__link__3QfAW {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.style_link__1kPh8 {
  text-decoration: none;
  transition: opacity 0.2s;
}
.style_link__1kPh8:hover {
  opacity: 0.8;
}

.style_main__Zjyqx {
  min-height: calc(100vh - 210px);
}


@media screen and (max-width: 770px) {
  .style_main__Zjyqx {
    min-height: calc(100vh - 170px);
  }
}
.style_card__1Le2w {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 363px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  text-align: left;
}

.style_card__add__2nn9l {
  display: -webkit-flex;
  display: flex;
  font-size: 14px!important;
  font-weight: 600!important;
  -webkit-align-items: center;
          align-items: center;
}

.style_card__add__2nn9l svg {
  margin-right: 5px;
}

.style_card__body__3mEB4 {
  padding: 24px 24px 12px;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}

.style_card__link__fP4ut {
  color: #000;
}

.style_card__footer__25Zgw {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px 24px 32px;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.style_card__item__W00Jx {
    margin-right: 8px;
}
.style_card__text__DSS_6 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    margin: 0 0 15px;
    padding: 0;
}
.style_card__text__DSS_6:last-child {
    margin-bottom: 0;
}

.style_card__image__3xIhV {
  height: 240px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.style_card__title__1iaT0 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 0 0 12px;
  padding: 0;
  display: block;
  text-align: left;
}

.style_card__time__1OSq7,
.style_card__author__TN_1Q {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
}

.style_card__time__1OSq7 {
  margin-bottom: 15px;
}

.style_card__time__1OSq7 svg,
.style_card__author__TN_1Q svg {
  margin-right: 10px;
}


@media screen and (max-width: 1000px) {
  .style_card__image__3xIhV {
    height: 160px;
  }

  .style_card__body__3mEB4 {
    padding: 12px;
  }

  .style_card__footer__25Zgw {
    padding: 12px;
  }
}

@media screen and (max-width: 550px) {
  .style_card__1Le2w {
    max-width: 100%;
  }
}


.style_cardList__2pI7x {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 40px;
  margin-bottom: 52px;
}

@media screen and (max-width: 860px) {
  .style_cardList__2pI7x {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 830px) {
  .style_cardList__2pI7x {
    grid-gap: 20px;
  }
}


@media screen and (max-width: 770px) {
  .style_cardList__2pI7x {
    grid-gap: 10px;
  }
}




@media screen and (max-width: 550px) {
  .style_cardList__2pI7x {
    grid-template-columns: 1fr;
  }
}

.style_button__1FFWl {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  background: none;
  border-radius: 6px;
  padding: 0px 24px;
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: background 0.2s;
  outline: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
  box-sizing: border-box;
}

.style_button__1FFWl:active {
  box-shadow: 0 0 7px #ddd;
}

.style_button_style_light__92RF5 {
  border: 1px solid #DEE2F2;
  background: #FFF;
  color: #4A61DD;
}

.style_button_style_light__92RF5:hover {
  background: #D8DEFF;
  color: #4A61DD;
  border: 1px solid transparent;
}

.style_button_style_light-blue__1mOBn {
  background: #F0F3FF;
  color: #4A61DD;
}

.style_button_style_light-blue__1mOBn:hover {
  background: #D8DEFF;
  color: #4A61DD;
}

.style_button_style_dark-blue__1cpq7 {
  background: #4A61DD;
  color: #FFF;
}

.style_button_disabled__3OKp4 {
  opacity: .6;
  cursor: not-allowed;
}

.style_button_style_dark-blue__1cpq7:hover {
  background: #2845DF;
}

.style_button_style_none__3iQIz {
  padding: 0;
}

.style_button_style_none__3iQIz:active {
  box-shadow: none;
}


@media screen and (max-width: 940px) {
  .style_button__1FFWl {
    font-size: 12px!important;
    padding: 0px 12px;
    height: 32px;
    line-height: 30px;
  }
}

.styles_title__2fhty {
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 60px;
  text-align: left;
}

@media screen and (max-width: 770px) {
  .styles_title__2fhty {
    margin-bottom: 30px;
    font-size: 22px;
  }
}

.styles_form__2nwxz {
  max-width: 770px;
  background: #FFF;
  border: 1px solid #EEE;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 54px 100px 70px 68px;
}

@media screen and (max-width: 730px) {
  .styles_form__2nwxz {
    padding: 20px;
  }
}
.styles_input__2Dg_j {
  margin-bottom: 35px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.styles_inputLabel__u_wTn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.styles_inputLabelText__WsyhD {
  padding-right: 30px;
  text-align: left;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.styles_inputField__3eqTj {
  -webkit-flex-basis: 370px;
          flex-basis: 370px;
  padding: 12px 18px;
  border-radius: 6px;
  font-size: 16px;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 730px) {
  .styles_inputLabel__u_wTn {
    display: block;
    text-align: left;
  }

  .styles_inputLabelText__WsyhD {
    margin-bottom: 10px;
  }

  .styles_inputField__3eqTj {
    width: 100%;
  }
}
.styles_menuButton__1RUEF {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  background: none;
  height: 48px;
  line-height: 48px;
  border-radius: 6px;
  padding: 0px 24px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: background 0.2s;
  outline: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
  display: inline-block;
  background: #4A61DD;
  color: #FFF;
  -webkit-transform: translateY(-9px);
          transform: translateY(-9px);
}

.styles_menuButton__1RUEF:hover {
  background: #2845DF;
}

.styles_menuLink__3a59I {
  white-space: nowrap;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  padding: 0;
  font-weight: 500;
  line-height: 19px;
  padding-bottom: 14px;
  margin-right: 28px;
}

.styles_menuLink__3a59I:last-child {
  margin-right: 0px;
}

.styles_menu__3-KEH {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}


@media screen and (max-width: 990px) {
  .styles_menuLink__3a59I {
    font-size: 14px;
    margin-right: 14px;
  }

  .styles_menuButton__1RUEF {
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    line-height: 22px;
    padding: 0px 10px;
  }
}


@media screen and (max-width: 900px) {
  .styles_menuLink__3a59I {
    font-size: 12px;
    margin-right: 8px;
  }
}

@media screen and (max-width: 770px) {
  .styles_menu__3-KEH {
    height: 50px;
  }

  .styles_menuLink__3a59I {
    padding-bottom: 0px;
    font-size: 14px;
  }

  .styles_menuButton__1RUEF {
    margin-top: 20px;
  }
}
.style_nav__1k-TH {
  height: 90px;
  width: 100%;
}

.style_nav__container__3EI6k {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
}

.style_nav__items__sxjNk {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  list-style: none;
  padding: 0;
  -webkit-align-items: center;
          align-items: center;
}

.style_nav__item__3KPy- {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 28px;
  height: 100%;
  color: #000000;
  cursor: pointer;
  position: relative;
}
.style_nav__item__3KPy-:last-child {
    margin-right: 0;
}

.style_nav__item_active__1h9Am {
    color: #4A61DD;
}
.style_nav__item_active__1h9Am:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: 20px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #4A61DD;
}

.style_nav__link__2rAY6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: inherit;
    padding-bottom: 14px;
    cursor: inherit;
    font-family: 'Montserrat', sans-serif;
}

.style_nav__link_active__1hmzl {
  border-bottom: 1px solid #4A61DD;
  color: #4A61DD;
}

.style_orders-count__3zVqa {
  display: inline-block;
  padding: 0px 12px;
  background: #4A61DD;
  color: #FFF;
  font-weight: 500;
  height: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
  margin-left: 10px;
  border-radius: 36px;
}


@media screen and (max-width: 990px) {
  .style_nav__link__2rAY6 {
    font-size: 14px;
  }

  .style_nav__item__3KPy- {
    margin-right: 14px;
  }
}


@media screen and (max-width: 900px) {
  .style_nav__link__2rAY6 {
    font-size: 10px;
  }

  .style_nav__item__3KPy- {
    margin-right: 8px;
  }

  .style_orders-count__3zVqa {
    height: 16px;
    font-size: 10px;
    padding: 0px 6px;
  }
}

@media screen and (max-width: 770px) {
  .style_nav__1k-TH {
    height: 50px;
  }

  .style_nav__link__2rAY6 {
    padding-bottom: 6px;
  }
}

@media screen and (max-width: 770px) {
  .style_nav__1k-TH {
    height: auto;
    position: relative;
  }

  .style_nav__link__2rAY6 {
    font-size: 16px;
  }

  .style_nav__items__sxjNk {
    display: block;
    position: absolute;
    left: -200px;
    height: auto;
    top: 0;
  }

  .style_orders-count__3zVqa {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }

  .style_nav__item__3KPy- {
    margin-bottom: 20px;
  }
}

.styles_tag__3GiD4 {
  display: inline-block;
  padding: 4px 12px;
  font-weight: 500;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1;
  border-radius: 24px;
  margin-right: 8px;
}

.styles_tag_type_orange__3WC5y {
  background: rgba(234, 146, 99, 0.12);
  color: #E26C2D;
}

.styles_tag_type_green__1NcrN {
  background: rgba(73, 182, 78, 0.1);
  color: #49B64E;
}

.styles_tag_type_violet__77qtS {
  background: rgba(135,117,210,0.1);
  color: #8775D2;
}
.styles_tags-container__3e5wH {
  margin-bottom: 20px;
}
.styles_textarea__2Mg4h {
  margin-bottom: 35px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.styles_textareaLabel__12gQH {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.styles_textareaLabelText__3CySs {
  padding-right: 30px;
  text-align: left;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.styles_textareaField__1wfhC {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    resize: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    padding: 12px 18px;
    -webkit-flex-basis: 370px;
            flex-basis: 370px;
    width: 100%;
}
.styles_textareaField__1wfhC:focus {
    border: 1px solid #4A61DD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 730px) {
  .styles_textareaLabel__12gQH {
    display: block;
    text-align: left;
  }

  .styles_textareaLabelText__3CySs {
    margin-bottom: 10px;
  }

  .styles_textareaField__1wfhC {
    width: 100%;
  }
}

.styles_checkbox__1WBUC {
  position: relative;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ddd;
  border: none;
  outline: none;
  margin-right: 8px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0px;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_checkbox-container__1vy_E {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 24px;
  font-size: 16px;
  font-weight: 500;
}

.styles_checkbox_active__22dG2 {
  background: currentColor;
  box-shadow: 0 0 7px #ddd;
}

.styles_checkbox_style_green__21FBk {
  color: #49B64E;
}

.styles_checkbox_style_orange__1L3eq {
  color: #E26C2D;
}

.styles_checkbox_style_purple__n9mH6 {
  color: #8775D2;
}


.styles_checkboxGroup__MPxb8 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 40px;
}

.styles_label__11lhQ {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: left;
}

.styles_checkboxGroupItems__2W443 {
  -webkit-flex-basis: 370px;
          flex-basis: 370px;
  display: -webkit-flex;
  display: flex;
}


.styles_pagination__5PNtl {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 80px;
}

.styles_paginationItem__vduM0 {
  width: 46px;
  height: 46px;
  border-radius: 46px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #000;
  font-size: 20px;
}

.styles_paginationItemActive__RvB_m {
  color: #FFF;
  background-color: #4A61DD;
  cursor: default;
}

.styles_arrow__2O0xu {
  cursor: pointer;
  max-width: 16px;
}

.styles_arrowLeft__1uUAX {
  margin-right: 21px;
}

.styles_arrowRight__358Cq {
  margin-left: 21px;
}

.styles_arrowDisabled__FpXGK {
  opacity: .3;
  cursor: not-allowed;
}

.styles_purchase__3jCZl {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px;
    max-width: 600px;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    width: 100%;
    position: relative;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-bottom: 18px;
    text-align: left;
    list-style-type: none;
    border-bottom: 1px solid #EEEEEE;
}

.styles_purchaseDelete__3hbXC {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #858383;
    text-decoration: none;
}

.styles_recipeLink__1OeX- {
  color: #000;
  text-decoration: none;
}

.styles_purchase__3jCZl:last-child {
    margin-bottom: 0;
}

.styles_purchaseContent__keeIU {
    display: grid;
    position: relative;
    grid-template-areas: 'image title'
    'image text';
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
}

.styles_purchaseImage__W86F7 {
    width: 72px;
    height: 72px;
    grid-area: image;
    border-radius: 50%;
    background-size: cover;
    background-position: 50% 50%;
    align-self: center;
}

.styles_purchaseText__UIKXV {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    max-width: 300px;
    color: #000;
    grid-area: text;
    align-self: start;
    margin: 0 0 28px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.styles_purchaseText__UIKXV:last-child {
    margin-bottom: 0;
}

.styles_purchaseText__UIKXV svg {
    margin-right: 10px;
}

.styles_purchaseTitle__3tToa {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    max-width: 300px;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin: 10px 0 13px;
    grid-area: title;
}


.styles_purchaseList__3EswM {
  margin: 0;
  padding: 0;
  margin-bottom: 74px;
}
.styles_subscription__Xzqjb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 363px;
  width: 100%;
  text-align: left;
}

.styles_subscriptionHeader__3uShT {
  padding: 31px 26px 17px;
}

.styles_subscriptionRecipeLink__-ZapC {
  color: #000;
  text-decoration: none;
}

.styles_subscriptionBody__OTWDH {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  padding: 17px 26px 16px;
}

.styles_subscriptionFooter__1gUON {
  padding: 16px 26px 26px;
}

.styles_subscriptionTitle__1mj9j {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 0;
}

.styles_subscriptionItems__1Cr8s {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}

.styles_subscriptionItem__1W3iR {
    position: relative;
    margin-bottom: 18px;
    padding-bottom: 18px;
    list-style-type: none;
}

.styles_subscriptionItem__1W3iR:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: #EEEEEE;
    height: 1px;
    width: calc(100% - 72px);
}

.styles_subscriptionItem__1W3iR:last-child {
    margin-bottom: 0;
}

.styles_subscriptionItem__1W3iR:last-child:after {
    content: none;
}

.styles_subscriptionLink__2OiuV {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #4A61DD;
}

.styles_subscriptionRecipe__fuw4j {
    display: grid;
    position: relative;
    grid-template-areas: 'title image'
    'text image';
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
}

.styles_subscriptionRecipe__fuw4j {
    display: grid;
    position: relative;
    grid-template-areas: 'title image'
    'text image';
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
}

.styles_subscriptionRecipeTitle__3yWI- {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    max-width: 200px;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin: 10px 0 13px;
    grid-area: title;
}

.styles_subscriptionRecipeImage__1PxRj {
    width: 72px;
    height: 72px;
    grid-area: image;
    border-radius: 50%;
    object-fit: cover;
}

.styles_subscriptionRecipeText__Rysn6 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    max-width: 200px;
    color: #000;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    grid-area: text;
    align-self: start;
    margin: 0 0 28px;
}

.styles_subscriptionRecipeText__Rysn6 svg {
  margin-right: 10px;
}

.styles_subscriptionRecipeText__Rysn6:last-child {
    margin-bottom: 0;
}

.styles_subscriptionMore__3NaTD {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #4A61DD;
}

.styles_subscriptionButton__WtOm- {
  width: 100%;
}


@media screen and (max-width: 860px) {
  .styles_subscription__Xzqjb {
    max-width: 100%;
  }
}



.styles_subscriptionList__ErQid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 40px;
    margin-bottom: 52px;
}

@media screen and (max-width: 970px) {
  .styles_subscriptionList__ErQid {
    grid-gap: 20px;
  }
}

@media screen and (max-width: 860px) {
  .styles_subscriptionList__ErQid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 660px) {
  .styles_subscriptionList__ErQid {
    grid-template-columns: 1fr;
  }
}
.styles_container__dkFAk {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-bottom: 35px;
}

.styles_label__1cGjM {
  padding-right: 30px;
  text-align: left;
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 230px;
}

.styles_fileInput__3HjP3 {
  display: none;
}

.styles_button__xzu5F {
  width: 182px;
  cursor: pointer;
  height: 48px;
  background: #DEDEDE;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 20px;
}

.styles_image__3qdoo {
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 5px;
  border: 1px solid black;
}
.styles_container__3ukwm {
  background: #FFF;
  border: 1px solid rgba(98, 98, 98, 0.2);
  box-sizing: border-box;
  max-height: 100px;
  overflow-y: scroll;
  padding: 10px;
  position: absolute;
  top: 42px;
  left: 230px;
  min-width: 220px;
  text-align: left;
}

@media screen and (max-width: 820px) {
  .styles_container__3ukwm {
    left: 0;
    top: 70px;
  }
}

.styles_loading__2kHA8 {
  position: fixed;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100vh;
  width: 100%;
  line-height: 100vh;
  text-align: center;
}

.styles_menuButton__zn68y {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  display: none;
}

.styles_menuButton__zn68y img {
  width: 100%;
}

.styles_appMenuToggled__3Esea {
  -webkit-transform: translateX(200px);
          transform: translateX(200px);
}

@media screen and (max-width: 770px) {
  .styles_menuButton__zn68y {
    display: block
  }
}
.styles_title__2i73B {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 770px) {
  .styles_title__2i73B {
    display: block;
  }
}

.styles_form__2_42b {
  margin: 0 auto 50px;
}

.styles_button__1jD3X {
  font-size: 18px;
}

.styles_form__24nV3 {
  margin: 0 auto 50px;
}

.styles_button__146Sy {
  font-size: 18px;
}

.styles_single-card__1yTTj {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-align: left;
}

.styles_single-card__buttons__2hhQ8 {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 40px;
}

.styles_single-card__link__hcssy {
  color: #000;
}

.styles_single-card__button__365Op {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 16px;
}

.styles_single-card__button__365Op svg {
  margin-right: 5px;
}

.styles_single-card__button__365Op:last-child {
  margin-right: 0px;
}

.styles_single-card__edit__Mb_wc {
  margin-left: 40px;
  color: #000;
  text-decoration: underline;
}

.styles_single-card__text__2_OKG, .styles_single-card__text_with_link__2KSfr {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.styles_single-card__text__2_OKG svg {
  margin-right: 12px;
}

.styles_single-card__image__O135K {
  object-fit: cover;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  height: 480px;
  width: 480px;
  border-radius: 15px;
  max-width: 100%;
}

.styles_single-card__info__2_cny {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding-left: 60px;
}


.styles_single-card__header-info__3B9iz {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
  margin: 0 0 18px;
}

.styles_single-card__title__2QMPq {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;

  line-height: 49px;
  color: #000;
  margin: 0;
}

@media screen and (max-width: 820px) {
  .styles_single-card__text_with_link__2KSfr {
    display: block;
  }

  .styles_single-card__edit__Mb_wc {
    margin-left: 0px;
    margin-top: 20px;
    display: inline-block;
  }

  .styles_single-card__1yTTj {
    display: block;
  }

  .styles_single-card__info__2_cny {
    padding-left: 0px;
  }

  .styles_single-card__image__O135K {
    margin-bottom: 40px;
  }

  .styles_single-card__buttons__2hhQ8 {
    display: block;
  }

  .styles_single-card__button__365Op {
    margin-bottom: 16px;
  }
}


.styles_ingredients__23QxR {
  
}

.styles_ingredients__list__2hEKd {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  max-height: 104px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.styles_ingredients__title__226Os {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 0 0 12px;
}

.styles_ingredients__list-item__N6ZnN {
    font-size: 16px;
    line-height: 26px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #000000;
    margin: 0;
    padding: 0;
    width: -webkit-max-content;
    width: max-content;
}


.styles_description__g6e8v {
  padding-bottom: 20px;
}

.styles_description__title__3nNk2 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin: 0 0 12px;
}


@media screen and (max-width: 820px) {
  .styles_description__g6e8v {
    padding-bottom: 30px;
  }
}
.styles_form__1C9cK {
  margin: 0 auto 50px;
}

.styles_ingredientsInputs__2xMrI {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.styles_ingredientsAmountInputContainer__J47Hl {
  display: -webkit-flex;
  display: flex;
}

.styles_ingredientsInput__1_gvc {
  width: 230px;
}

.styles_ingredientsLabel__2kpb9 {
  min-width: 230px;
}
.styles_measurementUnit__3pdyC {
  -webkit-align-self: center;
          align-self: center;
  margin-left: 20px;
}

.styles_ingredientsAmountValue__1dKSF {
  max-width: 100px;
}

.styles_ingredientsNameInput__1lt0D,
.styles_ingredientsAmountInput__36_2M,
.styles_measurementUnit__3pdyC {
  margin-bottom: 20px;
}

.styles_ingredientAdd__1BTZ3 {
  cursor: pointer;
  text-align: left;
  margin-bottom: 30px;
  padding-left: 230px;
}

.styles_ingredientsAdded__3bKEZ {
  padding-left: 230px;
  text-align: left;
}

.styles_cookingTime__TycvY {
  display: -webkit-flex;
  display: flex;
}

.styles_button__2JiOs {
  font-size: 18px;
}

.styles_cookingTimeUnit__2HL4S {
  -webkit-align-self: center;
          align-self: center;
  margin-bottom: 35px;
}

.styles_fileInput__ZVUKG {
  margin-bottom: 72px;
}

.styles_cookingTimeLabel__16YZq {
  min-width: 230px;
}

.styles_ingredientsAddedItemTitle__3HW-0 {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-right: 4px;
}

.styles_ingredientsAddedItem__3fdeb {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.styles_ingredientsTimeInput__fAfmn {
  margin-right: 10px;
}

.styles_ingredientsAddedItemRemove__1JN4w {
  padding-left: 20px;
  color: red;
  cursor: pointer;
}

.styles_actions__2aEfy {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_deleteRecipe__1fvHk {
  font-size: 18px;
  text-decoration: underline;
  margin-left: 26px;
  color: #000;
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .styles_ingredientsInputs__2xMrI {
    display: block;
    text-align: left;
  }

  .styles_ingredientAdd__1BTZ3 {
    padding-left: 0px;
  }

  .styles_checkboxGroup__riLxc {
    display: block;
  }

  .styles_checkboxGroupLabel__30Bhk {
    margin-bottom: 10px;
  }

  .styles_checkboxGroupTags__2Anuw {
    display: block;
  }

  .styles_checkboxGroupItem__y0aFL {
    margin-bottom: 6px;
  }

  .styles_cookingTime__TycvY {
    display: block;
    text-align: left;
  }

  .styles_ingredientsAdded__3bKEZ {
    padding-left: 0px;
  }
}
.styles_cart__1uk4Z {
  text-align: left;
}

.styles_container__3UZRF {
  margin-bottom: 70px;
}
.styles_title__1FW6l {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 770px) {
  .styles_title__1FW6l {
    display: block;
  }
}

.styles_form__3XFkE {
  margin: 0 auto 50px;
}

.styles_ingredientsInputs__1W-NV {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.styles_ingredientsInput__1zzql {
  width: 230px;
}

.styles_ingredientsAmountInputContainer__2WwmL {
  display: -webkit-flex;
  display: flex;
}

.styles_ingredientsLabel__naQhi {
  min-width: 230px;
}

.styles_measurementUnit___KgnR {
  -webkit-align-self: center;
          align-self: center;
  margin-left: 20px;
}

.styles_cookingTime__2vRcp {
  display: -webkit-flex;
  display: flex;
}

.styles_cookingTimeUnit__1KQaC {
  -webkit-align-self: center;
          align-self: center;
  margin-bottom: 35px;
}

.styles_cookingTimeLabel__2PIfJ {
  min-width: 230px;
}

.styles_ingredientsAmountValue__2matT {
  max-width: 100px;
}

.styles_ingredientsTimeInput__3oqdd {
  margin-right: 10px;
}

.styles_ingredientsNameInput__1Z9NR,
.styles_ingredientsAmountInput__1F2dx,
.styles_measurementUnit___KgnR {
  margin-bottom: 20px;
}

.styles_ingredientAdd__3fc32 {
  cursor: pointer;
  text-align: left;
  margin-bottom: 30px;
  padding-left: 230px;
  text-decoration: underline;
}

.styles_ingredientsAdded__35vNf {
  padding-left: 230px;
  text-align: left;
}

.styles_ingredientsAddedItemTitle__1tzK0 {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-right: 4px;
}

.styles_fileInput__3NvXo {
  margin-bottom: 72px;
}

.styles_button__f_Q9Z {
  font-size: 18px;
}

.styles_ingredientsAddedItem__31W7j {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.styles_ingredientsAddedItemRemove__2lTAu {
  padding-left: 20px;
  color: red;
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .styles_ingredientsInputs__1W-NV {
    display: block;
    text-align: left;
  }

  .styles_ingredientAdd__3fc32 {
    padding-left: 0px;
  }

  .styles_checkboxGroup__2SWIr {
    display: block;
  }

  .styles_checkboxGroupLabel__3wJM8 {
    margin-bottom: 10px;
  }

  .styles_checkboxGroupTags__fP_gP {
    display: block;
  }

  .styles_checkboxGroupItem__1aTHO {
    margin-bottom: 6px;
  }

  .styles_cookingTime__2vRcp {
    display: block;
    text-align: left;
  }

  .styles_ingredientsAdded__35vNf {
    padding-left: 0px;
  }
}
.styles_title__2Q9Ue {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.styles_container__2tmhz {
  text-align: left;
}

.styles_titleText__16MdJ {
  margin-bottom: 23px;
}

.styles_buttonSubscribe__1C1fa {
  margin-bottom: 46px;
}

@media screen and (max-width: 770px) {
  .styles_title__2Q9Ue {
    display: block;
  }
}

.styles_form__3iU2- {
  margin: 0 auto 50px;
}
