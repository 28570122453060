.container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 35px;
}

.label {
  padding-right: 30px;
  text-align: left;
  flex: 1;
  max-width: 230px;
}

.fileInput {
  display: none;
}

.button {
  width: 182px;
  cursor: pointer;
  height: 48px;
  background: #DEDEDE;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.image {
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 5px;
  border: 1px solid black;
}